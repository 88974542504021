exports.components = {
  "component---src-gatsby-templates-dato-applicationpage-tsx": () => import("./../../../src/gatsby-templates/datoApplicationpage.tsx" /* webpackChunkName: "component---src-gatsby-templates-dato-applicationpage-tsx" */),
  "component---src-gatsby-templates-dato-articlepage-tsx": () => import("./../../../src/gatsby-templates/datoArticlepage.tsx" /* webpackChunkName: "component---src-gatsby-templates-dato-articlepage-tsx" */),
  "component---src-gatsby-templates-dato-frontpage-tsx": () => import("./../../../src/gatsby-templates/datoFrontpage.tsx" /* webpackChunkName: "component---src-gatsby-templates-dato-frontpage-tsx" */),
  "component---src-gatsby-templates-dato-hoodpage-tsx": () => import("./../../../src/gatsby-templates/datoHoodpage.tsx" /* webpackChunkName: "component---src-gatsby-templates-dato-hoodpage-tsx" */),
  "component---src-gatsby-templates-dato-indexpage-tsx": () => import("./../../../src/gatsby-templates/datoIndexpage.tsx" /* webpackChunkName: "component---src-gatsby-templates-dato-indexpage-tsx" */),
  "component---src-gatsby-templates-dato-kotimme-articlepage-tsx": () => import("./../../../src/gatsby-templates/datoKotimmeArticlepage.tsx" /* webpackChunkName: "component---src-gatsby-templates-dato-kotimme-articlepage-tsx" */),
  "component---src-gatsby-templates-dato-kotimme-frontpage-tsx": () => import("./../../../src/gatsby-templates/datoKotimmeFrontpage.tsx" /* webpackChunkName: "component---src-gatsby-templates-dato-kotimme-frontpage-tsx" */),
  "component---src-gatsby-templates-dato-kotimme-issue-tsx": () => import("./../../../src/gatsby-templates/datoKotimmeIssue.tsx" /* webpackChunkName: "component---src-gatsby-templates-dato-kotimme-issue-tsx" */),
  "component---src-gatsby-templates-dato-kotimme-recipe-tsx": () => import("./../../../src/gatsby-templates/datoKotimmeRecipe.tsx" /* webpackChunkName: "component---src-gatsby-templates-dato-kotimme-recipe-tsx" */),
  "component---src-gatsby-templates-dato-news-article-tsx": () => import("./../../../src/gatsby-templates/datoNewsArticle.tsx" /* webpackChunkName: "component---src-gatsby-templates-dato-news-article-tsx" */),
  "component---src-gatsby-templates-visma-kustannuspaikka-tsx": () => import("./../../../src/gatsby-templates/vismaKustannuspaikka.tsx" /* webpackChunkName: "component---src-gatsby-templates-visma-kustannuspaikka-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kampanjat-jatekuuri-index-jsx": () => import("./../../../src/pages/kampanjat/jatekuuri/index.jsx" /* webpackChunkName: "component---src-pages-kampanjat-jatekuuri-index-jsx" */),
  "component---src-pages-kampanjat-joulunlajittelu-index-tsx": () => import("./../../../src/pages/kampanjat/joulunlajittelu/index.tsx" /* webpackChunkName: "component---src-pages-kampanjat-joulunlajittelu-index-tsx" */),
  "component---src-pages-kampanjat-lampokuuri-index-jsx": () => import("./../../../src/pages/kampanjat/lampokuuri/index.jsx" /* webpackChunkName: "component---src-pages-kampanjat-lampokuuri-index-jsx" */),
  "component---src-pages-kampanjat-oodikodeille-index-tsx": () => import("./../../../src/pages/kampanjat/oodikodeille/index.tsx" /* webpackChunkName: "component---src-pages-kampanjat-oodikodeille-index-tsx" */),
  "component---src-pages-kampanjat-vesikuuri-index-jsx": () => import("./../../../src/pages/kampanjat/vesikuuri/index.jsx" /* webpackChunkName: "component---src-pages-kampanjat-vesikuuri-index-jsx" */),
  "component---src-pages-kampanjat-vesikuuri-tiimalasi-jsx": () => import("./../../../src/pages/kampanjat/vesikuuri/tiimalasi.jsx" /* webpackChunkName: "component---src-pages-kampanjat-vesikuuri-tiimalasi-jsx" */),
  "component---src-pages-kampanjat-vesikuuri-veden-matka-jsx": () => import("./../../../src/pages/kampanjat/vesikuuri/veden-matka.jsx" /* webpackChunkName: "component---src-pages-kampanjat-vesikuuri-veden-matka-jsx" */),
  "component---src-pages-kampanjat-vesikuuri-vesigallup-ari-orko-jsx": () => import("./../../../src/pages/kampanjat/vesikuuri/vesigallup/ari-orko.jsx" /* webpackChunkName: "component---src-pages-kampanjat-vesikuuri-vesigallup-ari-orko-jsx" */),
  "component---src-pages-kampanjat-vesikuuri-vesigallup-index-jsx": () => import("./../../../src/pages/kampanjat/vesikuuri/vesigallup/index.jsx" /* webpackChunkName: "component---src-pages-kampanjat-vesikuuri-vesigallup-index-jsx" */),
  "component---src-pages-kampanjat-vesikuuri-vesigallup-kirsti-salmi-jsx": () => import("./../../../src/pages/kampanjat/vesikuuri/vesigallup/kirsti-salmi.jsx" /* webpackChunkName: "component---src-pages-kampanjat-vesikuuri-vesigallup-kirsti-salmi-jsx" */),
  "component---src-pages-kampanjat-vesikuuri-vesigallup-mirja-koivunen-jsx": () => import("./../../../src/pages/kampanjat/vesikuuri/vesigallup/mirja-koivunen.jsx" /* webpackChunkName: "component---src-pages-kampanjat-vesikuuri-vesigallup-mirja-koivunen-jsx" */),
  "component---src-pages-kampanjat-vesikuuri-vesigallup-mohamed-yusuf-jsx": () => import("./../../../src/pages/kampanjat/vesikuuri/vesigallup/mohamed-yusuf.jsx" /* webpackChunkName: "component---src-pages-kampanjat-vesikuuri-vesigallup-mohamed-yusuf-jsx" */),
  "component---src-pages-kampanjat-vesikuuri-vesikuuri-seuranta-jsx": () => import("./../../../src/pages/kampanjat/vesikuuri/vesikuuri-seuranta.jsx" /* webpackChunkName: "component---src-pages-kampanjat-vesikuuri-vesikuuri-seuranta-jsx" */),
  "component---src-pages-kampanjat-viherkuuri-index-jsx": () => import("./../../../src/pages/kampanjat/viherkuuri/index.jsx" /* webpackChunkName: "component---src-pages-kampanjat-viherkuuri-index-jsx" */)
}

