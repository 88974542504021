import i18next from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
// import all namespaces (for the default language, only)
import nsEnApartmentSearch from "./translations/en/apartmentSearch.json"
import nsEnCommon from "./translations/en/common.json"
import nsEnFooter from "./translations/en/footer.json"
import nsEnLayout from "./translations/en/layout.json"
import nsEnTampuuri from "./translations/en/tampuuri.json"
import nsFiApartmentSearch from "./translations/fi/apartmentSearch.json"
import nsFiCommon from "./translations/fi/common.json"
import nsFiFooter from "./translations/fi/footer.json"
import nsFiLayout from "./translations/fi/layout.json"
import nsFiTampuuri from "./translations/fi/tampuuri.json"
import nsSvApartmentSearch from "./translations/sv/apartmentSearch.json"
import nsSvCommon from "./translations/sv/common.json"
import nsSvFooter from "./translations/sv/footer.json"
import nsSvLayout from "./translations/sv/layout.json"
import nsSvTampuuri from "./translations/sv/tampuuri.json"

/**
 * All possible "locale" values defined in Dato as Supported languages.
 * https://harvia.admin.datocms.com/admin/environment
 */
export const datoLocales = ["en", "fi", "sv"] as const

/**
 * Supported locale in Dato.
 */
export type DatoLocale = (typeof datoLocales)[number]

const resources = {
  // tyypitykset tulkitaan fi aviamen alta
  fi: {
    apartmentSearch: nsFiApartmentSearch,
    common: nsFiCommon,
    footer: nsFiFooter,
    layout: nsFiLayout,
    tampuuri: nsFiTampuuri,
  },
  sv: {
    apartmentSearch: nsSvApartmentSearch,
    common: nsSvCommon,
    footer: nsSvFooter,
    layout: nsSvLayout,
    tampuuri: nsSvTampuuri,
  },
  en: {
    apartmentSearch: nsEnApartmentSearch,
    common: nsEnCommon,
    footer: nsEnFooter,
    layout: nsEnLayout,
    tampuuri: nsEnTampuuri,
  },
} as const

/**
 * i18next configuration
 * https://www.i18next.com/overview/configuration-options
 */

i18next
  // .use(initReactI18next) // bind react-i18next to the instance (tehdään wrapRootElement() avulla)
  .use(LanguageDetector)
  .init({
    fallbackLng: "fi",
    resources,
    ns: ["apartmentSearch", "common", "layout", "tampuuri", "footer"],
    defaultNS: "common",
    returnObjects: true,
    debug: process.env.NODE_ENV === "development",
    saveMissing: true,
    // missingKeyHandler: (lng, ns, key, fallbackValue) => {
    //   console.warn("Key missing", lng, ns, key, fallbackValue)
    // },
    interpolation: {
      escapeValue: false, // not needed for react, react is already safe from xss
    },
    react: {
      useSuspense: false, // kokeilu. Halutaan SSR ja Suspense ei oikein sovi sinne (vielä?)
      bindI18n: false, // estää re-rendauksen languageChange eventistä (oletus). Localen vaihto tehdään itse PageWrapper komponentissa (lisätty wrapPageElement() avulla).
    },
    // resource loading in init function, defaults to async (using setTimeout).
    // Set it to false if your backend loads resources sync - that way calling i18next.t after init is possible without relying on the init callback.
    // This option only works for sync (blocking) loading backend, like i18next-sync-fs-backend!
    initImmediate: false, // Important for SSR to work
    preload: ["fi", "sv", "en"], // array of languages to preload. Important on serverside to assert translations are loaded before rendering views.
    detection: {
      // https://github.com/i18next/i18next-browser-languageDetector#detector-options
      // order and from where user language should be detected
      order: ["cookie", "localStorage", "sessionStorage", "navigator", "htmlTag", "path"],
      // cache user language on
      caches: ["cookie", "localStorage"],
    },

    // i18next-fetch-backend
    backend: {
      // path where resources get loaded from, or a function
      // returning a path:
      // function(lngs, namespaces) { return customPath; }
      // the returned path will interpolate lng, ns if provided like giving a static path
      loadPath: "/i18n/{{lng}}/{{ns}}.json",
      // your backend server supports multiloading
      // /locales/resources.json?lng=de+en&ns=ns1+ns2
      allowMultiLoading: false,
    },
  })

i18next.languages = ["fi", "sv", "en"]

export { i18next }
