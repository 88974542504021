import React from "react"
import { useTranslation } from "react-i18next"
import { DatoLocale } from "../i18n/i18next"

/**
 * gatsby-node'sta gatsby-template'lle annettu data.
 * Käytettävissä usePageContext() hookin avulla.
 */
export type PageContextData = {
  locale: DatoLocale
  originalId?: string
  routeId?: string // lokalisoidun Routen id. Mukana vain Dato Routesta generoiduissa sivuissa
  originalRouteId?: string // routen lokalisoimaton id jonka avulla noudettavissa kaikki lokalisoidut versiot. Mukana vain Dato Routesta generoiduissa sivuissa
  kohdeId?: number // kiinteistösivun Tampuuri kohdeId
  kotimmeArticleId?: string
  kotimmeRecipeId?: string
  newsArticleId?: string
}

export const PageContext = React.createContext<PageContextData>({
  locale: "fi",
})

type PageContextProviderProps = {
  value: PageContextData
  children?: React.ReactNode
}

export const PageContextProvider = (props: PageContextProviderProps) => {
  // console.debug("PageContentProvider init", props)
  const { value, children } = props

  const { i18n } = useTranslation()
  i18n.changeLanguage(value.locale)

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>
}
